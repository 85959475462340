import React from "react"

import PostCard from "./post_card"

function BlogsList({ posts, title, description }) {
  const renderedPosts = posts.map(p => <PostCard key={p.node.frontmatter.title} post={p} /> );

  return (
    <div className="relative mb-12">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-left">
          <div>
          <h2 className="text-3xl leading-9 tracking-tight font-light text-gray-900 sm:text-4xl sm:leading-10">
            { title }
          </h2>
          </div>
          <p className="mt-3 max-w-2xl text-xl leading-7 text-gray-500 sm:mt-4">
            { description }
          </p>
        </div>
        <div className="mt-12 grid gap-5 mx-auto lg:grid-cols-3 lg:max-w-none">
          { renderedPosts }
        </div>
      </div>
    </div>
  )
}

export default BlogsList
