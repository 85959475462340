import React from "react"

import { Link } from "gatsby"

import Img from "gatsby-image"

const PostCard = ({ post }) => {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden mb-8">
      <div className="flex-shrink-0">
        <Img className="h-36 w-full object-cover" fluid={post.node.postImage.childImageSharp.fluid} />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm leading-5 font-medium text-indigo-600">
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-300 text-black">
              { post.node.frontmatter.tag }
            </span>
          </p>
          <Link to={post.node.frontmatter.path} className="block">
            <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
              { post.node.frontmatter.title }
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-500">
            { post.node.frontmatter.description }
            </p>
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div>
            <p className="text-sm leading-5 font-medium text-gray-900">
              <a href="#" className="hover:underline">
                { post.node.frontmatter.author }
              </a>
            </p>
            <div className="flex text-sm leading-5 text-gray-500">
              <time dateTime="2020-03-16">
                { post.node.frontmatter.date }
              </time>
              <span className="mx-1">
                &middot;
              </span>
              <span>
                { post.node.fields.readingTime.text }
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostCard